import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import theme from '@artemis/theme';

const PROMOTION_TYPE = {
  MERCHANT_REWARD: 'MERCHANT_REWARD',
  MERCHANT_DISCOUNT: 'MERCHANT_DISCOUNT',
  TOP_MERCHANT: 'TOP_MERCHANT',
  EMPLOYEE_PRICING: 'EMPLOYEE_PRICING',
};

export const PromotionShape = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(PROMOTION_TYPE)),
  summary: PropTypes.string,
});

const Chip = styled.span`
  ${props => props.theme.typography.caption};
  background-color: ${props => props.$bgColor};
  color: ${props => props.theme.rtColors.white};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  padding: 4px 10px;
  border-radius: 6px;
  text-transform: uppercase;
`;

const PromoChip = ({
  promotions,
  topMerchantsFilterApplied = false,
  className,
}) => {
  const merchantReward = promotions?.find(
    ({ type, summary }) => type === PROMOTION_TYPE.MERCHANT_REWARD && !!summary,
  );

  const isTopMerchant = promotions?.some(
    ({ type }) => type === PROMOTION_TYPE.TOP_MERCHANT,
  );

  const employeePricingPromo = promotions?.find(
    ({ type, summary }) =>
      type === PROMOTION_TYPE.EMPLOYEE_PRICING && !!summary,
  );

  let content;
  const bgColor = theme.rtColors.teal500;

  // This is in priority order
  if (employeePricingPromo) {
    content = employeePricingPromo.summary;
  } else if (isTopMerchant && (!topMerchantsFilterApplied || !merchantReward)) {
    content = <FormattedMessage entry="landing.promotions.topMerchant" />;
  } else if (merchantReward) {
    content = merchantReward.summary;
  } else {
    return null;
  }

  return (
    <Chip
      data-testid="merchant-card-promo-chip"
      className={className}
      $bgColor={bgColor}
    >
      {content}
    </Chip>
  );
};

PromoChip.propTypes = {
  promotions: PropTypes.arrayOf(PromotionShape),
  topMerchantsFilterApplied: PropTypes.bool,
  className: PropTypes.string,
};

export default PromoChip;
