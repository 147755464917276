import React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';

const Status = styled.p`
  text-align: center;
`;

const StatusInfo = styled.p`
  ${({ theme }) => theme.typography.bodySmall};
  text-align: center;
`;

const StateContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  background: rgba(0, 0, 0, 0.65);
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScheduleOrderSection = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 1000px;
  height: 40px;
  margin-top: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const ScheduleText = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
  padding-right: 15px;
`;

const MerchantStateOverlay = ({
  label,
  subLabel,
  showScheduledOrdering = false,
}) => {
  const scheduleOrderText = useFormatMessage({ entry: 'global.scheduleOrder' });
  if (!label) return null;
  return (
    <StateContainer data-testid="merchant-state-overlay">
      <Status>
        <FormattedMessage entry={label} />
      </Status>
      {showScheduledOrdering && (
        <ScheduleOrderSection aria-label={scheduleOrderText}>
          <ScheduleIcon
            sx={{
              color: 'black',
              fontSize: 20,
              paddingRight: '7px',
              paddingLeft: '15px',
            }}
          />
          <ScheduleText>{scheduleOrderText}</ScheduleText>
        </ScheduleOrderSection>
      )}
      {subLabel && <StatusInfo>{subLabel}</StatusInfo>}
    </StateContainer>
  );
};

MerchantStateOverlay.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.node,
  showScheduledOrdering: PropTypes.bool,
};

export default MerchantStateOverlay;
