import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';

const SearchIcon = styled(ResponsiveImage).attrs({ id: 'icons.search.img' })`
  display: inline-block;
  height: 24px;
  position: absolute;
  width: 24px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

const HiddenSubmit = styled.input.attrs({ type: 'submit' })`
  display: none;
`;

const InputEndButton = styled.button`
  width: 48px;
  position: absolute;
  right: 1px;
  top: 1px;
  padding-right: 12px;
  padding-left: 10px;
  bottom: 1px;
  border: none;
  background: linear-gradient(to right, rgba(245, 245, 245, 0), #f5f5f5 15px);
  border-radius: 0 8px 8px 0;
`;

const Icon = styled(ResponsiveImage)`
  height: 24px;
  width: 24px;
  display: block;
  margin: auto 0 auto auto;
`;

const Search = ({ placeholder, searchQuery, onChange, clearSearch }) => {
  const searchInputLabel = useFormatMessage({ entry: 'global.searchInput' });

  return (
    <>
      <SearchIcon />
      <input
        aria-label={searchInputLabel}
        placeholder={placeholder}
        value={searchQuery}
        onChange={onChange}
      />
      <HiddenSubmit />
      {searchQuery && (
        <InputEndButton onClick={clearSearch} type="button">
          <Icon id="icons.clearsearch.img" />
        </InputEndButton>
      )}
    </>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.string,
  onChange: PropTypes.func,
  clearSearch: PropTypes.func,
};

export default Search;
