import styled from 'styled-components';
import ResponsiveImage from '@artemis/components/ResponsiveImage';

const LocationIcon = styled(ResponsiveImage).attrs({
  id: 'icons.location.img',
})`
  display: inline-block;
  height: 24px;
  position: absolute;
  width: 24px;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export default LocationIcon;
