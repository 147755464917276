/**
 *
 * Scrollable Category List
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useDetectTouchDevice from '@artemis/utils/useDetectTouchDevice';
import CategoryTag from './CategoryTag';

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1236px;
  margin: 10px auto 0;
  width: 100%;
  ${({ theme }) => theme.isTablet`
     margin: 30px auto 0;
   `}
`;

const ScrollableList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  overflow: hidden;
  ${({ theme }) => theme.isTablet`
    padding: 10px 32px;
  `}
  ${props =>
    props.showOverflow &&
    css`
      overflow: auto;
      ${props.theme.noScrollbars};
    `};
`;

export const processCategoriesByScreenWidth = (categories, screenWidth) => {
  if (!categories) {
    return categories;
  }
  const count = 7;
  const anchor = 790;
  const step = 95;
  if (screenWidth <= anchor && screenWidth > anchor - step * 1) {
    return categories.slice(0, count);
  }
  if (screenWidth <= anchor - step * 1 && screenWidth > anchor - step * 2) {
    return categories.slice(0, count - 1);
  }
  if (screenWidth <= anchor - step * 2 && screenWidth > anchor - step * 3) {
    return categories.slice(0, count - 2);
  }
  if (screenWidth <= anchor - step * 3 && screenWidth > anchor - step * 4) {
    return categories.slice(0, count - 3);
  }
  return categories;
};

const ScrollableCategoryList = ({
  categories,
  handleCategoryClick,
  showAll = false,
}) => {
  const [screenWidth, setScreenWidth] = useState(1080);
  const isTouchDevice = useDetectTouchDevice();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!categories || categories.length < 6) {
    return null;
  }

  const processCategories = showAll || isTouchDevice;
  const processedCategories = processCategories
    ? categories
    : processCategoriesByScreenWidth(categories, screenWidth);

  return (
    <Container>
      <ScrollableList showOverflow={isTouchDevice || showAll}>
        {processedCategories.map(category => (
          <CategoryTag
            key={category.id}
            imageUrl={category.imageUrl}
            name={category.name}
            onClick={() => handleCategoryClick(category.name)}
            noLastMargin={!isTouchDevice}
          />
        ))}
      </ScrollableList>
    </Container>
  );
};

ScrollableCategoryList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    }),
  ),
  handleCategoryClick: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
};

export default ScrollableCategoryList;
