import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.button`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  align-items: center;
  max-width: 100px;
  padding: 0px 20px 0px 0px;
  border: none;
  background-color: transparent;
  ${({ noLastMargin }) =>
    noLastMargin
      ? css`
          :last-child {
            padding-right: 0px;
          }
        `
      : css`
          :last-child {
            padding-right: 16px;
          }
        `}
  width: 100px;
  ${({ theme }) => theme.isTablet`
    width: 9vw;
    padding-right: 0px;
    :last-child {
      padding-right: 0px;
    }
  `}
`;

const Image = styled.img`
  border-radius: 50%;
  max-height: 100px;
  max-width: 100px;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  ${({ theme }) => theme.isTablet`
    height: 9vw;
    width: 9vw;
  `}
`;

const Name = styled.p`
  width: 100%;
  ${({ theme }) => theme.typography.bodySmall};
  ${({ theme }) => theme.isTablet`
    ${theme.typography.body};
  `};
  text-align: center;
  ${props => props.theme.typography.lineClamp(2)};
  text-overflow: ellipsis;
  display: block;
`;

const CategoryTag = ({ imageUrl, name, onClick, noLastMargin }) => (
  <Container
    onClick={onClick}
    noLastMargin={noLastMargin}
    data-testid="categoryTagContainer"
  >
    <Image src={imageUrl} data-testid="categoryTagImage" alt="" />
    <Name data-testid="categoryTagName">{name}</Name>
  </Container>
);

CategoryTag.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  noLastMargin: PropTypes.bool,
};

export default CategoryTag;
