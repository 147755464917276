import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '@artemis/integrations/contentful/utils';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const Button = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.palette.primary};
  ${({ theme }) => theme.typography.button};
  padding: 16px 72px;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
`;

const ShowMoreButton = ({ className, onClick = () => {} }) => (
  <ButtonContainer className={className}>
    <Button onClick={onClick}>
      <FormattedMessage entry="brand.showMore" />
    </Button>
  </ButtonContainer>
);

ShowMoreButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ShowMoreButton;
