/**
 *
 * SelectionBox
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { CheckboxOff, CheckboxOn, RadioOff, RadioOn } from './icons';

const Container = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.25s ease;
  & svg {
    flex-shrink: 0;
    margin-right: ${({ labelPosition }) => labelPosition === 'right' && '12px'};
  }
  &:hover {
    background: ${({ theme }) =>
      theme.palette.hover.onLightBg(theme.palette.background.default)};
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CHECKBOX = 'checkbox';
export const RADIO = 'radio';
export const QUANTITY = 'quantity';

/**
 * SelectionBox component description
 */
const SelectionBox = ({
  selected,
  children,
  className,
  onClick,
  onChange,
  disabled,
  type,
  labelPosition = 'right',
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  let Icon;
  switch (type) {
    case CHECKBOX: {
      if (selected) {
        Icon = CheckboxOn;
      } else {
        Icon = CheckboxOff;
      }
      break;
    }
    case RADIO:
    default: {
      if (selected) {
        Icon = RadioOn;
      } else {
        Icon = RadioOff;
      }
    }
  }

  return (
    <Container className={className} labelPosition={labelPosition}>
      {labelPosition === 'left' && children}
      <Icon color={theme.palette.primary} data-testid="select-icon" />
      {labelPosition === 'right' && children}
      <Input
        data-testid="select-input"
        type={type}
        checked={selected}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    </Container>
  );
};

SelectionBox.propTypes = {
  /**
   * Override styling
   */
  className: PropTypes.string,
  /**
   * SelectionBox type, either radio or checkbox
   */
  type: PropTypes.oneOf([RADIO, CHECKBOX]),
  /**
   * State of SelectionBox
   */
  selected: PropTypes.bool,
  /**
   * Children represents inside SelectionBox
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  /**
   * on change function for input
   */
  onClick: PropTypes.func,
  /**
   * on change function for input
   */
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['left', 'right']),
};

SelectionBox.defaultProps = {
  selected: false,
  className: '',
  onClick: () => {},
  onChange: () => {},
  type: 'radio',
  disabled: false,
};

export default SelectionBox;
