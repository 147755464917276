const MAPS_SCRIPT_ID = 'mapsApiScriptId';

const runGoogleMapsFunc = callbackFn => {
  const API_KEY = process.env.RT_GOOGLE_MAPS_API_KEY;
  if (!document.getElementById(MAPS_SCRIPT_ID)) {
    // In case the Google Maps script from _document.jsx doesn't load
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    script.async = true;
    script.id = MAPS_SCRIPT_ID;
    window.onInitMap = [callbackFn];
    script.onload = () => window.onInitMap.forEach(callback => callback());
    document.body.appendChild(script);
  } else if (!window.google) {
    window.onInitMap.push(callbackFn);
  } else {
    callbackFn();
  }
};

export default runGoogleMapsFunc;
