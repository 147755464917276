import React from 'react';
import PropTypes from 'prop-types';

const sharedPropTypes = { color: PropTypes.string };

export const CheckboxOff = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="16.5"
      height="16.5"
      rx="1.25"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);
CheckboxOff.propTypes = sharedPropTypes;

export const CheckboxOn = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H16C17.11 0 18 0.9 18 2V16C18 17.1 17.11 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0ZM2 9L7 14L16 5L14.59 3.58L7 11.17L3.41 7.59L2 9Z"
      fill={color}
    />
  </svg>
);
CheckboxOn.propTypes = sharedPropTypes;

export const RadioOff = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
  </svg>
);
RadioOff.propTypes = sharedPropTypes;

export const RadioOn = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke={color} strokeWidth="2" />
      <circle cx="10" cy="10" r="5" fill={color} />
    </svg>
  </svg>
);
RadioOn.propTypes = sharedPropTypes;

export const Plus = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 11V7.8855H13V11H16.1145V13H13V16.1145H11V13H7.8855V11H11Z"
      fill={color}
    />
  </svg>
);
Plus.propTypes = sharedPropTypes;

export const Minus = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1145 11H7.8855V13H16.1145V11Z"
      fill={color}
    />
  </svg>
);
Minus.propTypes = sharedPropTypes;
