import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: relative;
  margin-bottom: 16px;

  ${({ theme }) => theme.isTablet`
    width: calc(50% - 7px);
    margin-bottom: 0;
  `}

  > input {
    line-height: 16px;
    background: ${props => props.theme.rtColors.black100};
    border: none;
    border-radius: 8px;
    padding: 16px 52px;
    width: 100%;
    box-sizing: border-box;
  }
`;

const HalfWidthSearchContainer = ({ children }) => (
  <Container>{children}</Container>
);

HalfWidthSearchContainer.propTypes = {
  children: PropTypes.node,
};

export default HalfWidthSearchContainer;
