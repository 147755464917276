import runGoogleMapsFunc from './runGoogleMapsFunc';

const geocodeLatLng = latlng =>
  new Promise((resolve, reject) => {
    runGoogleMapsFunc(() => {
      const Geocoder = window.google?.maps?.Geocoder;
      const geocode = new Geocoder();
      geocode.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    });
  });

const getGeocodeWithPlaceId = id =>
  new Promise((resolve, reject) => {
    runGoogleMapsFunc(() => {
      const Geocoder = window.google?.maps?.Geocoder;
      const geocode = new Geocoder();
      geocode.geocode({ placeId: id }, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    });
  });

const getAddressComponents = components => {
  const result = {};
  components.forEach(({ types, ...item }) =>
    types.forEach(type => {
      if (!result[type]) {
        result[type] = item;
      }
    }),
  );
  return result;
};

const getCountryCodeFromLatLng = async latLng => {
  const place = await geocodeLatLng(latLng);
  const components = getAddressComponents(place?.address_components); // eslint-disable-line camelcase
  return components?.country?.short_name; // eslint-disable-line camelcase
};

export {
  runGoogleMapsFunc,
  geocodeLatLng,
  getGeocodeWithPlaceId,
  getAddressComponents,
  getCountryCodeFromLatLng,
};
