import { useEffect, useState } from 'react';

const useDetectTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window);
  }, []);
  return isTouchDevice;
};

export default useDetectTouchDevice;
