import React, { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import runGoogleMapsFn from '@artemis/integrations/maps/runGoogleMapsFunc';

/*
  Simplified Google autocomplete, no style biases.
  onPlacesChanged is the only unique API, otherwise just enhances an input tag,
  passing through all props
*/
const GoogleMapsAutocomplete = ({ onPlacesChanged, onChange, ...props }) => {
  const inputRef = useRef();
  const inputLabel = useFormatMessage({
    entry: 'global.googleMapsAutocomplete',
  });

  useEffectOnce(() => {
    const enhanceMapsInput = () => {
      const InputBoxAutoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
      );

      InputBoxAutoComplete.addListener('place_changed', () => {
        const place = InputBoxAutoComplete.getPlace();
        if (!place.geometry) {
          return;
        }
        onPlacesChanged(place);
        onChange({ target: { value: place.formatted_address } });
      });

      InputBoxAutoComplete.addListener('keydown', e => {
        if (e.keyCode === 13) {
          e.preventDefault();
        }
      });
    };
    runGoogleMapsFn(enhanceMapsInput);
  });

  return (
    <input
      data-testid="google-maps-autocomplete"
      aria-label={inputLabel}
      ref={inputRef}
      onChange={onChange}
      {...props}
    />
  );
};

GoogleMapsAutocomplete.propTypes = {
  onPlacesChanged: PropTypes.func,
  onChange: PropTypes.func,
};

export default GoogleMapsAutocomplete;
