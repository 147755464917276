import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import GoogleMapsAutocomplete from '@artemis/components/GoogleMapsAutocomplete';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import getCurrentPosition from '@artemis/utils/getCurrentPosition';
import { geocodeLatLng } from '@artemis/integrations/maps';
import { addToast } from '@artemis/store/toasts/slice';
import LocationIcon from './LocationIcon';

const InputEndButton = styled.button`
  width: 48px;
  position: absolute;
  right: 1px;
  top: 1px;
  padding-right: 12px;
  padding-left: 10px;
  bottom: 1px;
  border: none;
  background: linear-gradient(to right, rgba(245, 245, 245, 0), #f5f5f5 15px);
  border-radius: 0 8px 8px 0;
`;

const Icon = styled(ResponsiveImage)`
  height: 24px;
  width: 24px;
  display: block;
  margin: auto 0 auto auto;
`;

const LocationSearchField = ({
  setIsLoading,
  setLatLon,
  searchText,
  setSearchText,
  setPlaceId = () => {},
  setAddressComponents = () => {},
  setGeolocation = () => {},
  placeholder,
}) => {
  const dispatch = useDispatch();
  const searchPlaceHolder = useFormatMessage({
    entry: 'brand.search.placeholder',
  });

  const handleGeolocation = async () => {
    setIsLoading(true);
    try {
      const { coords } = await getCurrentPosition();
      setLatLon({ lat: coords.latitude, lon: coords.longitude });

      // reverse geocode to get the locality
      // set the search text to city, region
      geocodeLatLng({ lat: coords.latitude, lng: coords.longitude }).then(
        res => {
          // eslint-disable-next-line camelcase
          setSearchText(res?.formatted_address);
        },
      );
    } catch (error) {
      dispatch(addToast({ toast: { error: { message: error.message } } }));
    } finally {
      setIsLoading(false);
    }
  };

  const onPlacesChanged = data => {
    setIsLoading(true);
    const locationData = data?.geometry?.location;
    const placeId = data?.place_id;
    const addressComponents = data?.address_components;
    if (locationData && placeId && addressComponents) {
      setGeolocation({
        addressComponents,
        formattedAddress: data?.formatted_address,
        lat: locationData.lat(),
        lon: locationData.lng(),
        placeId,
      });
    }
    if (addressComponents) {
      setAddressComponents(addressComponents);
    }
    if (locationData && placeId) {
      setLatLon({ lat: locationData.lat(), lon: locationData.lng() });
      setPlaceId(placeId);
      setIsLoading(false);
    }
  };

  return (
    <>
      <LocationIcon />
      <GoogleMapsAutocomplete
        value={searchText}
        name="location-control-google-maps-search"
        placeholder={placeholder || searchPlaceHolder}
        onChange={e => setSearchText(e.target.value)}
        onPlacesChanged={onPlacesChanged}
      />
      <InputEndButton
        onClick={handleGeolocation}
        data-testid="button-location"
        type="button"
      >
        <Icon id="icons.gps.img" />
      </InputEndButton>
    </>
  );
};

LocationSearchField.propTypes = {
  setIsLoading: PropTypes.func,
  setLatLon: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  setPlaceId: PropTypes.func,
  setAddressComponents: PropTypes.func,
  setGeolocation: PropTypes.func,
  placeholder: PropTypes.string,
};

export default LocationSearchField;
