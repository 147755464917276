import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  activeOrdersInitialized,
  loadActiveOrders,
} from '@artemis/store/order/slice';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import { SUPPRESS_ORDER_BAR_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

const useLoadActiveOrders = () => {
  const dispatch = useDispatch();
  const { initialized, authenticated } = useContext(AuthenticationContext);
  const suppressOrderBar = useQueryCheck(SUPPRESS_ORDER_BAR_PARAM);

  const canShowOrderBar = authenticated && !suppressOrderBar;

  useEffect(() => {
    if (initialized) {
      if (canShowOrderBar) {
        dispatch(loadActiveOrders());
      } else {
        dispatch(activeOrdersInitialized());
      }
    }
  }, [canShowOrderBar, initialized]);
};

export default useLoadActiveOrders;
