import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const BANNER_HEIGHT = 48;

const bannerCss = css`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 12px;
  width: 100%;
  & > span {
    text-align: center;
  }
`;

const iconCss = css`
  flex-shrink: 0;
  & > picture {
    display: flex;
  }
`;

const AnchorBanner = styled.a`
  all: unset;
  cursor: pointer;
  ${bannerCss};
  &:focus {
    text-decoration: underline;
  }
`;
const ButtonBanner = styled.button`
  all: unset;
  cursor: pointer;
  ${bannerCss};
  &:focus {
    text-decoration: underline;
  }
`;
const DivBanner = styled.div`
  ${bannerCss};
`;
const LeftIcon = styled.span`
  ${iconCss}
  margin-right: 8px;
`;
const RightIcon = styled.span`
  ${iconCss}
  margin-left: 8px;
`;

const BannerProps = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};
/**
 * Renders a semantic full-width banner. If `href` is provided, the banner renders
 * as an anchor. If both `href` and `onClick` are provided, the banner renders as
 * an anchor. If only `onClick` is provided, the banner renders as a button. If
 * both `href` and `onClick` are provided, the banner renders as an anchor. If
 * neither are provided, the banner renders as a div.
 */
export const Banner = ({
  href,
  onClick,
  leftIcon,
  rightIcon,
  className,
  children,
  ...rest
}) => {
  let BannerContainer = DivBanner;
  if (href) {
    BannerContainer = AnchorBanner;
  } else if (onClick) {
    BannerContainer = ButtonBanner;
  }
  return (
    <BannerContainer
      className={className}
      href={href}
      onClick={onClick}
      {...rest}
    >
      {leftIcon && <LeftIcon aria-hidden>{leftIcon}</LeftIcon>}
      <span>{children}</span>
      {rightIcon && <RightIcon aria-hidden>{rightIcon}</RightIcon>}
    </BannerContainer>
  );
};

Banner.propTypes = BannerProps;
